import React from "react";
import "./HomeCompetencies.scss";
import { useTranslation } from "react-i18next";


const HomeCompetencies = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="competencies-section">
      <div className="competencies-wrapper">
        <img
          className="background"
          src="https://res.cloudinary.com/droimgtqi/image/upload/v1675227720/ukodesh-media/Vector_hbyjo9.png"
          alt=""
        />
        <div className="compentencies-content">
          <img
            className="overlayed-img"
            src="https://res.cloudinary.com/droimgtqi/image/upload/v1675227718/ukodesh-media/Group_90_t76qfl.png"
            alt=""
          />
          <div className="auxiliar-bg" />
          <div className="row centered-section">
            <span className="hero-title white-font">
            {t('homeComps.big-title-one')} <br /> {t('homeComps.big-title-two')} <br />{" "}
              <span className="dark-gradient-title">{t('homeComps.big-title-three')}</span>{" "}
            </span>
          </div>
          <div className="row centered-section">
            <div className="below-wrapper">
              <ul className="list-wrapper">
                <li className="white-font">
                  <img
                    src="https://res.cloudinary.com/droimgtqi/image/upload/v1675230077/ukodesh-media/bi_check-circle-fill_r1eszj.svg"
                    alt=""
                  />{" "}
                  {t('homeComps.firstComp-one')}{" "}
                  <b>{t('homeComps.firstComp-two')}</b>
                </li>
                <li className="white-font">
                  <img
                    src="https://res.cloudinary.com/droimgtqi/image/upload/v1675230077/ukodesh-media/bi_check-circle-fill_r1eszj.svg"
                    alt=""
                  />{" "}
                  {t('homeComps.secondComp-one')}<b>{t('homeComps.secondComp-two')}</b>
                </li>
                <li className="white-font">
                  <img
                    src="https://res.cloudinary.com/droimgtqi/image/upload/v1675230077/ukodesh-media/bi_check-circle-fill_r1eszj.svg"
                    alt=""
                  />{" "}
                  <b>{t('homeComps.thirdComp')}</b>
                </li>
                <li className="white-font">
                  <img
                    src="https://res.cloudinary.com/droimgtqi/image/upload/v1675230077/ukodesh-media/bi_check-circle-fill_r1eszj.svg"
                    alt=""
                  />{" "}
                  {t('homeComps.fourthComp-one')}<b>{t('homeComps.fourthComp-two')}</b>{t('homeComps.fourthComp-three')}{" "}
                  <br />{t('homeComps.fourthComp-four')}<b>{t('homeComps.fourthComp-five')}</b>
                </li>
                <li className="white-font">
                  <img
                    src="https://res.cloudinary.com/droimgtqi/image/upload/v1675230077/ukodesh-media/bi_check-circle-fill_r1eszj.svg"
                    alt=""
                  />{" "}
                  {t('homeComps.fifthComp-one')}<b>{t('homeComps.fifthComp-two')}</b>
                </li>
              </ul>
              <img
                className="positioned-laptop"
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1675367582/ukodesh-media/Group_89_lbevdo.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCompetencies;
