import React from "react";
import "./HomeHero.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const HomeHero = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="hero-section">
      <div className="hero-wrapper">
        <div className="left-side">
          <p className="helper-text">{t('homeHero.small-title')}</p>
          <span className="hero-title ">
          {t('homeHero.big-title-one')} <br />{" "}
            <span className="gradient-title">{t('homeHero.big-title-two')}</span> <br />{t('homeHero.big-title-three')}
          </span>
          <Link className="primary-btn mt-3" to="/contact/student">
          {t('homeHero.startButton')}{" "}
            <img
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1675030656/ukodesh-media/Group_83_krdf3e.svg"
              alt=""
            />
          </Link>
        </div>
        <div className="right-side">
          <img
            src="https://res.cloudinary.com/droimgtqi/image/upload/v1688594222/ukodesh-media/Group_183-min_oiru99.png"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
