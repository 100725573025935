import React from "react";
import "./HomeFeatures.scss";
import data from "../../../data/data-es.json";
import { useTranslation } from "react-i18next";

const HomeFeatures = () => {
  const featuresOne = data.featuresOne;
  const featuresTwo = data.featuresTwo;
  const {t, i18n} = useTranslation();
  const descriptionOne = [
    featuresOne
      .map(
        (feature, index) =>
          `<div class="feature-line" key=${index}>
          <div class="feature-img"><img src=${data.media.checkIcon} alt=${feature.id} /></div>
          <div class="feature-text">${t(`homeFeatures.features.${feature.id}.text`)}</div>
        </div>`
      )
      .join(""),
  ];

  const descriptionTwo = [
    featuresTwo
      .map(
        (feature, index) =>
          `<div class="feature-line" key=${index}>
          <div class="feature-img"><img src=${data.media.checkIcon} alt=${feature.id} /></div>
          <div class="feature-text">${t(`homeFeatures.features.${feature.id}.text`)}</div>
        </div>`
      )
      .join(""),
  ];

  return (
    <section className="features-section">
      <h1 className="big-helper-text ph-4">{t('homeFeatures.title')}</h1>
        <div className="inner-features">
          <div className="row featured-section">
            <img className="ft-img rs-image" style={{width: '320px'}} src={data.media.featureOne} alt="" />
            <div className="right-features">
              <div
                className="features-wrapper"
                dangerouslySetInnerHTML={{
                  __html: descriptionOne,
                }}
              ></div>
            </div>
          </div>
          <div className=" featured-section mt-4 reversed">
            <div className="left-features">
              <div
                className="features-wrapper features-card"
                dangerouslySetInnerHTML={{ __html: descriptionTwo }}
              ></div>
            </div>
            <img className="rs-image mt-8 mb-6 ml-12" src={data.media.featureTwo} alt="" />
          </div>
        </div>
    </section>
  );
};

export default HomeFeatures;
