import React from "react";
import AppBanner from "../../../components/AppBanner/AppBanner";

const BusinessBanner = () => {
  return (
    <section className="business-banner">
      <AppBanner />
    </section>
  );
};

export default BusinessBanner;
