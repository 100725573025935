import React from "react";
import "./HomeReviews.scss";
import ReviewCard from "../../../components/ReviewCard/ReviewCard";
import data from "../../../data/data-es.json";
import { useTranslation } from "react-i18next";

const HomeReviews = () => {
  const reviewsOne = data.studentsOne;
  const reviewsTwo = data.studentsTwo;
  const {t, i18n} = useTranslation();
  return (
    <section className="reviews-section">
      <span className="title-reviews">{t('homeReviews.title')}</span>
      <div className="row-one">
        {reviewsOne.map((review, index) => (
          <ReviewCard
            key={index}
            profilePicture={review.profilePicture}
            name={review.name}
            country={review.country}
            title={t(`homeReviews.reviews.${review.id}.title`)}
            text={t(`homeReviews.reviews.${review.id}.desc`)}
          />
        ))}
      </div>
      <div className="row-two">
        {reviewsTwo.map((review, index) => (
          <ReviewCard
            key={index}
            profilePicture={review.profilePicture}
            name={review.name}
            country={review.country}
            title={t(`homeReviews.reviews.${review.id}.title`)}
            text={t(`homeReviews.reviews.${review.id}.desc`)}
          />
        ))}
      </div>
    </section>
  );
};

export default HomeReviews;
