import React from "react";
import "./BusinessFeatures.scss";
import data from "../../../data/data-es.json";
import { useTranslation } from "react-i18next";

const BusinessFeatures = () => {
  const featuresOne = data.businessFeaturesOne;
  const featuresTwo = data.businessFeaturesTwo;
  const {t, i18n} = useTranslation();

  const descriptionOne = [
    featuresOne
      .map(
        (feature, index) =>
          `<div class="feature-line" key=${index}>
          <div class="feature-img"><img src=${data.media.checkIcon} alt="" /></div>
          <div class="feature-text">${t(`businessFeatures.features.${feature.id}.text`)}</div>
        </div>`
      )
      .join(""),
  ];

  const descriptionTwo = [
    featuresTwo
      .map(
        (feature, index) =>
          `<div class="feature-line" key=${index}>
          <div class="feature-img"><img src=${data.media.checkIcon} alt="" /></div>
          <div class="feature-text">${t(`businessFeatures.features.${feature.id}.text`)}</div>
        </div>`
      )
      .join(""),
  ];

  return (
    <section className="business-features">
      <section className="features-title mt-10">
        <div className="title-wrapper">
          <span>
            {t('businessFeatures.title-one')}{" "}
            <span className="gradient-title">{t('businessFeatures.title-two')}</span>
          </span>
        </div>
      </section>
      <section className="features-details">
        <div className="details-wrapper mt-8 mb-8">
          <div className="row directioner">
            <div className="left-side">
              <img className="mr-6" width={"70%"}
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1677709082/Group_165_e6bsug.png"
                alt=""
              />
            </div>
            <div className="right-side">
              <div
                dangerouslySetInnerHTML={{ __html: descriptionOne }}
                className="inner-ft-details-wrapper"
              ></div>
            </div>
          </div>
          <div className="row directioner ft-reversed">
            <div className="left-side">
              <div
                dangerouslySetInnerHTML={{ __html: descriptionTwo }}
                className="inner-ft-details-wrapper"
              ></div>
            </div>
            <div className="right-side">
              <img className="ml-6" width={"70%"}
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1677709083/Group_89_r3pc2d.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default BusinessFeatures;
