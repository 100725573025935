import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./BusinessHero.scss";
import data from "../../../data/data-es.json";
import { useTranslation } from "react-i18next";

const BusinessHero = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 912;
  const {t, i18n} = useTranslation();

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });
  const hero = data.businessHero;
  return (
    <section className="business-hero">
      <div className="hero-wrapper">
        <div className="left-side">
        {width < breakpoint ? <h2 className="title-md"> {t('businessBanner.header-one')} <span className="gradient-title">{t('businessBanner.header-two')}</span> </h2> : null}
          <img src={hero.heroBanner} alt="" />
        </div>
        <div className="right-side">
          {width > breakpoint ? <h2 className="title-md"> {t('businessBanner.header-one')} <span className="gradient-title">{t('businessBanner.header-two')}</span> </h2> : null}
          <span className="hero-title">{t('businessBanner.title')}</span>
          {width > breakpoint ? <h1>{t('businessBanner.bottom-line')}</h1>: null}
          <Link className="bsns-btn mt-3" to="/contact/business">
          {t('businessBanner.startButton')}{" "}
            <img
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1677723416/Group_83_i0d0q2.svg"
              alt=""
            />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BusinessHero;
