import React from 'react'
import './HomePractices.scss';
import { useTranslation } from 'react-i18next';

const HomePractices = () => {
    const {t, i18n} = useTranslation();
    return (
        <section className='practices-section'>
            <p className='centered-text text-md ph-6'>{t('homePracts.title-one')} <label className="secondary-gradient">{t('homePracts.title-two')}</label> <br />{t('homePracts.title-three')}</p>
            <div className="background-practices row centered-section mt-4">
                <div className="tablet-img">
                    <img className='tablet-girl' src="https://res.cloudinary.com/droimgtqi/image/upload/v1676249456/ukodesh-media/GetPaidStock_8_ma4hcg.png" alt="" />
                    <img className='badge-level' src="https://res.cloudinary.com/droimgtqi/image/upload/v1676249620/ukodesh-media/Group_34_opapqx.svg" alt="" />
                </div>
                <img className='practices' src="https://res.cloudinary.com/droimgtqi/image/upload/v1676249457/ukodesh-media/dexway-languages2_1_w2wuhk.png" alt="" />
            </div>
        </section>
    )
}

export default HomePractices