import React from "react";
import { Link } from "react-router-dom";
import "./ContactStudent.scss";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import data from "../../../data/data-es.json";
import ContactForm from "../ContactForm";
import { useTranslation } from "react-i18next";

const ContactStudent = () => {
  const templateID = "template_0vhwo87";
  const contact = data.contactSection;
  const {t, i18n} = useTranslation();

  const inputs = [
    {
      id: 0,
      name: "from_name",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[0].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[0].id}.err`),
      required: true,
    },
    {
      id: 1,
      name: "from_lName",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[1].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[1].id}.err`),
      required: true,
    },
    {
      id: 2,
      name: "user_phone",
      type: "number",
      placeholder: t(`homeContact.inputs.${contact[2].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[2].id}.err`),
      required: true,
    },
    {
      id: 3,
      name: "user_email",
      type: "email",
      placeholder: t(`homeContact.inputs.${contact[3].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[3].id}.err`),
      required: true,
    },
    {
      id: 4,
      name: "message",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[4].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[4].id}.err`),
      required: true,
    },
  ];
  return (
    <section className="contact-student">
      <div className="title-wrapper">
        <h1>{t('homeContact.title')}</h1>
        <span>
        {t('homeContact.email-one')}{" "}
          <a href="mailto:info@ukodesh.com">
            <b>info@ukodesh.com</b>
          </a>{" "}
          {t('homeContact.email-two')}
        </span>
      </div>
      <div className="inner-wrapper mt-4">
        <div className="left-side">
          <img src={data.media.featureOne} width={256} alt="" />
          <span className="mt-3 txt-ctc">{t('homeContact.contact-us')}</span>
          <div className="divider mt-2" />
          <div className="media-wrapper mt-2">
            <div className="detail-wrapper">
              <FaPhoneAlt />
              <span className="detail-txt ml-1">{"+52 (33) 1344 1347"}</span>
            </div>
            <div className="detail-wrapper mt-1">
              <FaEnvelope />
              <span className="detail-txt ml-1">
                <a href="mailto:info@ukodesh.com">info@ukodesh.com</a>
              </span>
            </div>
          </div>
        </div>
        <div className="right-side">
          <ContactForm cert={false} inputs={inputs} templateID={templateID} />
        </div>
      </div>
      <span className="mt-8">
      {t('homeContact.businessInterest')}{" "}
        <Link to="/contact/business">
          <b>{t('homeContact.contact-here')}</b>
        </Link>
      </span>
    </section>
  );
};

export default ContactStudent;
