import React, { useEffect, useState } from "react";
import ButtonLevel from "../../components/ButtonLevel/ButtonLevel";
import { useTranslation } from "react-i18next";


const LevelSelect = ({
  filterBy,
  levels,
  handleClick,
  setIsActive,
  levelName,
  activeElement,
  unactiveElement,
  activeSelector,
  unactiveSelector,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 913;
  const { t, i18n } = useTranslation();


  const ButtonDesk = () =>
    levels
      .filter((level) => level.layoutPosition === filterBy)
      .map((level, index) => (
        <ButtonLevel
          key={index}
          levelName={level.levelName}
          handleClick={handleClick}
          text={t(`homeLevels.levels.${level.id}.desc`)}
          setIsActive={setIsActive}
          activeElement={activeElement}
          unactiveElement={unactiveElement}
          activeSelector={activeSelector}
          unactiveSelector={unactiveSelector}
        />
      ));

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  });

  return <>{width > breakpoint ? <ButtonDesk /> : ""}</>;
};

export default LevelSelect;
