import React from 'react';
import './Certification.scss';

const CertificationC = () => {
    return (
        <section className='cert-section'>
        
        </section>
    )
}

export default CertificationC;