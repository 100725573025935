import React from "react";
import "./HomeLevels.scss";
import Levels from "../../../components/Levels/Levels";
import { useTranslation } from "react-i18next";

const HomeLevels = () => {
  const white = "white-font";
  const activeBar = "active-bar-home";
  const unactiveBar = "unactive-bar-home";
  const activeElement = "active-element-home";
  const unactiveElement = "unactive-element-home";
  const activeSelector = "active-selector-home";
  const unactiveSelector = "unactive-selector-home";

  const { t, i18n } = useTranslation();


  return (
    <section className="levels-section">
      <h1 className="big-helper-text">{t('homeLevels.title')}</h1>
      <div className="levels-home">
        <Levels
          colorFont={white}
          activeBar={activeBar}
          unactiveBar={unactiveBar}
          activeElement={activeElement}
          unactiveElement={unactiveElement}
          activeSelector={activeSelector}
          unactiveSelector={unactiveSelector}
        />
      </div>
    </section>
  );
};

export default HomeLevels;
