import React from "react";

const LevelText = ({ textLevel, colorFont, setIsActive, activeBar, unactiveBar }) => {
  return (
    <>
      <div
        className={`footer-wrapper ${
          setIsActive == "" ? unactiveBar : activeBar
        }  ${colorFont}`}
      >
        {textLevel}
      </div>
    </>
  );
};

export default LevelText;
