import React from "react";
import "./Cards.scss";

const Card = (props) => {
  return (
    <div className="card-container">
      <div className="card-header">
        <img src={props.imageUrl} alt="" />
      </div>
      <div className="card-body">
        <h3>{props.titleLanguage}</h3>
        <p>{props.textLanguage}</p>
      </div>
      <div className="card-footer">
        <a href={props.link}>{props.buttonText}</a>
      </div>
    </div>
  );
};

export default Card;
