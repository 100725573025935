import React from "react";
import AppBanner from "../../../components/AppBanner/AppBanner";

const HomeBanner = () => {
  const bgColor = "low-blue-bg";
  return (
    <>
      <AppBanner backgroundColor={bgColor} />
    </>
  );
};

export default HomeBanner;
