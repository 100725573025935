import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import './SwitcherLanguage.scss'
import { TbWorld } from 'react-icons/tb'

const SwitcherLanguage = ({ toggle }) => {
    const locales = {
        en: { title: 'English', img: "https://res.cloudinary.com/droimgtqi/image/upload/v1680285042/ukodesh-media/Group_1_i7macp.png" },
        es: { title: 'Español', img: "https://res.cloudinary.com/droimgtqi/image/upload/v1680285044/ukodesh-media/Vector_4_ri3btr.png" },
    };
    const { t, i18n } = useTranslation();
    const [expanded, setExpanded] = useState(false);


    return (
        <div className='language-wrapper'>
            <div onClick={() => setExpanded(!expanded)} className='switcher-language'>
                <TbWorld />
                <span className='language-mh'>{i18n.language}</span>
                <div className='switch-icon'>
                    <motion.span animate={{ rotate: expanded ? 0 : 45, x: 4 }}
                        transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}></motion.span>
                    <motion.span animate={{ rotate: expanded ? 0 : -45, x: 0 }}
                        transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}></motion.span>
                </div>
            </div>
            <motion.div key="content"
                initial={{ opacity: 0 }}
                animate={{ opacity: expanded ? "1" : "0" }}
                style={{ display: expanded ? "flex" : "none" }}
                transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                className="language-selector">
                {Object.keys(locales).map((locale) => (
                    <div className='language-detail' key={locale} onClick={() => (i18n.changeLanguage(locale), setExpanded(!expanded), toggle)}>
                        <img className='language-img' src={locales[locale].img} alt="" />
                        {locales[locale].title}
                    </div>
                ))}
            </motion.div>
        </div>
    )
}

export default SwitcherLanguage