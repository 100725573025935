import React from "react";
import "./ReviewCard.scss";

const ReviewCard = ({ profilePicture, name, country, title, text }) => {
  return (
    <div className="review-card">
      <div className="card-header">
        <div className="picture-wrapper">
          <img src={profilePicture} alt="" />
        </div>
        <div className="review-details">
          <span className="name">{name}</span>
          <span className="country">{country}</span>
        </div>
      </div>
      <div className="card-content">
        <span className="title-review">{title}</span>
        <span className="text-review">{`“${text}”.`}</span>
      </div>
    </div>
  );
};

export default ReviewCard;
