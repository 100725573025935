import React from "react";
import "./BusinessXFeatures.scss";
import data from "../../../data/data-es.json";
import { useTranslation } from "react-i18next";


const BusinessXFeatures = () => {
  const featuresOne = data.featuresOne;
  const featuresTwo = data.featuresTwo;
  const title = data.feature_title;
  const {t, i18n} = useTranslation();

  const descriptionOne = [
    featuresOne
      .map(
        (feature, index) =>
          `<div class="feature-line" key=${index}>
          <div class="feature-img"><img src=${data.media.checkIcon} alt=${feature.id} /></div>
          <div class="feature-text">${t(`homeFeatures.features.${feature.id}.text`)}</div>
        </div>`
      )
      .join(""),
  ];

  const descriptionTwo = [
    featuresTwo
      .map(
        (feature, index) =>
          `<div class="feature-line" key=${index}>
          <div class="feature-img"><img src=${data.media.checkIcon} alt=${feature.id} /></div>
          <div class="feature-text">${t(`homeFeatures.features.${feature.id}.text`)}</div>
        </div>`
      )
      .join(""),
  ];
  return (
    <section className="business-x-features">
      <section className="features-title mt-10">
        <div className="title-wrapper">
          <span className="big-blue-text">{t('homeFeatures.title')}</span>
        </div>
      </section>
      <section className="features-details">
        <div className="details-wrapper mt-8 mb-8">
          <div className="row directioner">
            <div className="left-side">
              <img
                className="mr-6"
                width={"70%"}
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1677777304/Group_180_qggo5x.png"
                alt=""
              />
            </div>
            <div className="right-side">
              <div
                dangerouslySetInnerHTML={{ __html: descriptionOne }}
                className="inner-ft-details-wrapper"
              ></div>
            </div>
          </div>
          <div className="row directioner ft-reversed mt-4">
            <div className="left-side">
              <div
                dangerouslySetInnerHTML={{ __html: descriptionTwo }}
                className="inner-ft-details-wrapper"
              ></div>
            </div>
            <div className="right-side">
              <img
                className="ml-6"
                width={"70%"}
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1677709083/Group_89_r3pc2d.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default BusinessXFeatures;
