import React from "react";
import "./Home.scss";
import HomeCompetencies from "./HomeCompetencies/HomeCompetencies";
import HomeHero from "./HomeHero/HomeHero";
import HomeLanguages from "./HomeLanguages/HomeLanguages";
import HomeLevels from "./HomeLevels/HomeLevels";
import HomeSkills from "./HomeSkills/HomeSkills";
import HomePractices from "./HomePractices/HomePractices";
import HomeFeatures from "./HomeFeatures/HomeFeatures";
import HomeReviews from "./HomeReviews/HomeReviews";
import HomePartners from "./HomePartners/HomePartners";
import HomeBanner from "./HomeBanner/HomeBanner";
import CertificationC from "../../components/Certification/Certification";

const Home = () => {
  return (
    <section className="home-landing">
      <HomeHero />
      <HomeCompetencies />
      <HomeSkills />
      <HomeLanguages />
      <HomeLevels />
      <HomePractices />
      <HomeFeatures />
      <HomeReviews />
      <HomePartners />
      <CertificationC />
      <HomeBanner />
    </section>
  );
};

export default Home;
