import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Topbar.scss";

const Topbar = () => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ fontSize: 12 }} className="top-bar">
       <span className="white-font mb-2">Consulta los resultados de:</span> 
      <Link
        style={{ marginLeft: 4 }}
        className="white-font"
        target="_blank"
        to={"https://evaluation.eecertification.com/certifications/new"}
      >
        {t("header.certLink")}
      </Link> <span className="white-font medium">&nbsp; | </span> <Link
        style={{ marginLeft: 4 }}
        className="white-font"
        target="_blank"
        to={"https://myscore.ukodesh.com/"}
      >
        {t("header.acredLink")}
      </Link>
    </div>
  );
};

export default Topbar;
