import React from "react";
import "./BusinessLanguages.scss";
import data from "../../../data/data-es.json";
import { useTranslation } from "react-i18next";

const BusinessLanguages = () => {
  const flags = data.flags;
  const {t, i18n} = useTranslation();

  return (
    <section className="business-languages py-10">
      <div className="languages-wrapper">
        <h1 className="big-blue-text">{t('businessLangs.title')}</h1>
        <div className="flags-wrapper">
          {flags.map((flag) => (
            <div key={flag.id} className="flag-col flex justify-center items-center flex-col">
              <img src={flag.icon} />
              <h6 style={{ margin: 0 }}>{t(`businessLangs.langs.${flag.id}.flag`)}</h6>
            </div>
          ))}
        </div>
          <p
            className="languages-desc text-md mt-6"
            dangerouslySetInnerHTML={{ __html: t('businessLangs.desc') }}
          ></p>
      </div>
    </section>
  );
};

export default BusinessLanguages;
