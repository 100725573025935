import React from "react";
import Carousel, { CarouselItem, CarouselTitle } from "../Carousel/Carousel";
import data from "../../data/data-es.json";
import ButtonLevel from "../ButtonLevel/ButtonLevel";
import { useTranslation } from "react-i18next";



const LevelsMobile = ({
  uniqueCounter,
  handleClick,
  colorFont,
  textLevel,
  isActive,
  levelName,
  activeBar,
  unactiveBar,
  activeElement,
  unactiveElement,
  activeSelector,
  unactiveSelector,
}) => {
  const levels = data.levels;
  const {t, i18n} = useTranslation();

  return (
    <div className="levels-mobile">
      <Carousel
        handleClick={handleClick}
        text={textLevel}
        setIsActive={isActive}
      >
        {levels.map((level, index) => (
          <CarouselItem key={index}>
            <ButtonLevel
              key={index}
              text={level.levelDesc}
              levelName={level.levelName}
              setIsActive={isActive}
              activeElement={activeElement}
              unactiveElement={unactiveElement}
              activeSelector={activeSelector}
              unactiveSelector={unactiveSelector}
            />
            <div className="levels-footer" style={{color: 'white'}} >
              {t(`homeLevels.levels.${level.id}.desc`)}
            </div>
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  );
};

export default LevelsMobile;
