import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import SwitcherLanguage from "../SwitcherLanguage/SwitcherLanguage";
import { motion } from 'framer-motion'


const NavBar = ({ toggle, header, shared }) => {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);


  return (
    <>
      <div className="toggle-wrapper">
        <nav className="navbar-section">
          <div className="mr-20 nav-drop" onMouseEnter={() => setExpanded(!expanded)} onMouseLeave={() => setExpanded(!expanded)}>
            <div className="nav-dropbutton">
              {t('header.center-services')}
              <div  className='ml-4 switch-icon'>
                <motion.span animate={{ rotate: expanded ? 0 : 45, x: 4 }}
                  transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}></motion.span>
                <motion.span animate={{ rotate: expanded ? 0 : -45, x: 0 }}
                  transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}></motion.span>
              </div>
            </div>
            <div className="drop-content">
              <Link className="capitalize" to="/">
                {t('header.language-center')}
              </Link>
              <Link className="capitalize" to="/certification">
              {t('header.certification')}
              </Link>
            </div>
          </div>
          <Link className="mr-30 capitalize" to="/business">
            {t('header.companies')}
          </Link>
          <Link className="capitalize" to="/contact">
            {t('header.contact')}
          </Link>
        </nav>
        <div className="buttons-section">
          <a target={"_blank"} href={shared.loginButtonLink} className="login-btn mr-24">
            {t('header.loginButton')}
          </a>
          <a target={"_blank"} href={shared.testButtonLink} className="test-btn">
            {t('header.testButton')}
          </a>
        </div>
        <SwitcherLanguage />
      </div>
      <div className="burger-wrapper">
        <GiHamburgerMenu onClick={toggle} />
      </div>
    </>
  );
};

export default NavBar;
