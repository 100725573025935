import React, { useState, useEffect } from "react";
import "./ButtonLevel.scss";

const ButtonLevel = ({
  text,
  handleClick,
  levelName,
  setIsActive,
  activeElement,
  unactiveElement,
  activeSelector,
  unactiveSelector,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1024;

  const isResponsive = () => {
    return setIsActive === text ? activeSelector : unactiveSelector;
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  return (
    <div className="button-wrapper">
      <div
        onClick={() => handleClick(text)}
        className={`button-level ${setIsActive === text ? activeElement : unactiveElement}`}
      >
        <span>{levelName}</span>
      </div>
      <div className={width > breakpoint ? isResponsive() : ""}></div>
    </div>
  );
};

export default ButtonLevel;
