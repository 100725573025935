import React, { useState, useEffect } from "react";
import "./BusinessPartners.scss";
import Partners from "../../../components/Partners/Partners";

const BusinessPartners = () => {
  return (
    <section className="business-partners mt-6">
      <Partners />
    </section>
  );
};

export default BusinessPartners;
