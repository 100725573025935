import React from "react";
import { useTranslation } from "react-i18next";
import ContactForm from "../Contact/ContactForm";
import Student from "./components/Student/Student";
import Feature from "./components/Feature/Feature";
import data from "../../data/data-es.json";
import "./Certification.scss";
import Certified from "../../components/Certified/Certified";

const Certification = () => {
  const { t, i18n } = useTranslation();
  const templateID = "template_0vhwo87";
  const students = data.students;
  const features = data.features;
  const contact = data.contactSection;


  const inputs = [
    {
      id: 0,
      name: "from_name",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[0].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[0].id}.err`),
      required: true,
    },
    {
      id: 1,
      name: "from_lName",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[1].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[1].id}.err`),
      required: true,
    },
    {
      id: 2,
      name: "user_phone",
      type: "number",
      placeholder: t(`homeContact.inputs.${contact[2].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[2].id}.err`),
      required: true,
    },
    {
      id: 3,
      name: "user_email",
      type: "email",
      placeholder: t(`homeContact.inputs.${contact[3].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[3].id}.err`),
      required: true,
    },
  ];

  return (
    <div id="certification">
      <main className="landing-sells">
        <section className="hero-banner">
          <div className="landing-wrapper">
            <div className="inner-wrapper">
              <div className="left-side">
                <div className="inner-text">
                  <span
                    dangerouslySetInnerHTML={{ __html: t("certPage.title") }}
                    className="hero-title"
                  ></span>
                  <p
                    dangerouslySetInnerHTML={{ __html: t("certPage.subTitle") }}
                    className="subtitle"
                  ></p>
                  <br />
                  {/*<h3 style={{ marginTop: '48px' }}>{t('certPage.onlyOn')} <span className='gradient-title'>Ukodesh Education.</span></h3>*/}
                </div>
                <div className="partners-line">
                  <img
                    src="https://res.cloudinary.com/droimgtqi/image/upload/v1676675951/ukodesh-media/untitled_1_1_fkp4sm.png"
                    alt=""
                  />
                  <img
                    src="https://res.cloudinary.com/droimgtqi/image/upload/v1700853985/ukodesh-media/sdsu_primary_logo_rgb_horizontal_full_color_1_l3sm89.png"
                    alt=""
                  />
                  <img
                    src="https://res.cloudinary.com/droimgtqi/image/upload/v1676675951/ukodesh-media/image_3_pqfy2s.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="right-side">
                <img
                  className="img-banner"
                  src="https://res.cloudinary.com/droimgtqi/image/upload/v1683317464/ukodesh-media/unnamed_1_o0d7xa.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="assessment-section">
          <div className="landing-wrapper">
            <div className="assessment-wrapper">
              <div className="left-side">
                <img
                  className="img-assessment"
                  src="https://res.cloudinary.com/droimgtqi/image/upload/v1688532077/ukodesh-media/Group_182-min_ob1cnk.png"
                  alt=""
                />
              </div>
              <div className="right-side">
                <div className="inner-text flex flex-col">
                  <span
                    style={{
                      marginBottom: 4,
                      fontSize: 24,
                      fontWeight: "bold",
                    }}
                    className="yellow-text"
                  >
                    {t("certPage.eTitle")}
                  </span>
                  <span className="f-24">
                    {t("certPage.englishLevel_One")}{" "}
                    <b>{t("certPage.englishLevel_Two")}</b>{" "}
                    {t("certPage.englishLevel_Three")}
                  </span>
                </div>
                <div className="max-form-width">
                  <ContactForm cert={true} inputs={inputs} templateID={templateID} />
                </div>
                <div className="inner-text f-12 grey-text">
                  <p>
                    <span className="red-text">*</span>
                    {t("certPage.form_fullfill")}
                  </p>
                </div>
              </div>
            </div>
            <div className="inner-text">
              <span className="f-18">
                {t("certPage.test_cert_one")}{" "}
                <b>SDSU LEADERS - SAN DIEGO STATE UNIVERSITY </b>
              </span>
            </div>
          </div>
        </section>
        <Certified />
        <section className="features-section">
          <div className="landing-wrapper">
            <h1 className="inner-text yellow-text">{t("certPage.benefits")}</h1>
            <div className="features-wrapper-cert">
              {features.map((feature, index) => (
                <Feature feature={feature} key={index} />
              ))}
            </div>
          </div>
        </section>
        <section className="students-section">
          <div className="landing-wrapper">
            <h1 style={{ margin: "0" }} className="yellow-text">
              {t("certPage.students")}
            </h1>
            <h4 style={{ paddingBottom: "40px" }} className="secondary-color">
              {t("certPage.reviews")}
            </h4>
            <div className="students-wrapper">
              {students.map((student, index) => (
                <Student
                  id={student.id}
                  key={index}
                  name={student.name}
                  state={student.state}
                  title={student.title}
                  text={student.text}
                  pic={student.pic}
                />
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Certification;
