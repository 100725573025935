import React from 'react'
import { useTranslation } from 'react-i18next';
import './Student.scss'

const Student = ({ pic, name, state, title, text, id }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="student-card">
            <div className="card-content">
                <span className="title-review">{t(`certPage.certStudents.${id}.title`)}</span>
                <span className="text-review">{t(`certPage.certStudents.${id}.text`)}.</span>
            </div>

            <div className="card-header">
                <div className="picture-wrapper">
                    <img src={pic} alt="" />
                </div>
            </div>
            <div className="review-details">
                <span className="name">{t(`certPage.certStudents.${id}.name`)}</span> &nbsp;| &nbsp;
                <span className="state">{t(`certPage.certStudents.${id}.state`)}</span>
            </div>
        </div>
    )
}

export default Student