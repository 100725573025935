import React from "react";
import "./FullBar.scss";
import { MdClose } from "react-icons/md";
import { FullMenu } from "./FullBarStyles";
import data from "../../data/data-es.json";
import { Link } from "react-router-dom";
import SwitcherLanguage from "../SwitcherLanguage/SwitcherLanguage";
import { useTranslation } from 'react-i18next';


const FullBar = ({ isOpen, toggle }) => {
  const header = data.footer;
  const shared = data.shared;
  const { t, i18n } = useTranslation(); 

  return (
    <FullMenu isOpen={isOpen}>
      <div className="menu-wrapper">
        <div className="close-button">
          <MdClose onClick={toggle} size={24}/>
        </div>
      </div>
      <div className="nav-wrapper">
        <nav className="navbar-section">
          <Link onClick={toggle} className="capitalize" to="/">
          {t('header.language-center')}
          </Link>
          <Link onClick={toggle} className="capitalize mt-2" to="/certification">
          {t('header.certification')}
          </Link>
          <Link onClick={toggle} className="capitalize mt-2" to="/business">
          {t('header.companies')}
          </Link>
          <Link onClick={toggle} className="capitalize mt-2" to="/contact">
          {t('header.contact')}
          </Link>
        </nav>
        <div className="buttons-section mt-4">
          <a onClick={toggle} target={"_blank"} href={shared.loginButtonLink} className="login-btn mt-1">
          {t('header.loginButton')}
          </a>
          <a onClick={toggle} target={"_blank"} href={shared.testButtonLink} className="test-btn mt-2">
          {t('header.testButton')}
          </a>
          <SwitcherLanguage toggle={toggle} />
        </div>
      </div>
    </FullMenu>
  );
};

export default FullBar;
