import React from "react";
import "./HomeLanguages.scss";
import Card from "../../../components/Card/Card";
import data from "../../../data/data-es.json";
import { useTranslation } from "react-i18next";

const HomeLanguages = () => {
  const cardsOne = data.cardsOne;
  const cardsTwo = data.cardsTwo;
  const flags = data.flags;
  const { t, i18n } = useTranslation();


  return (
    <section className="languages-section">
      <div className="info-wrapper">
        <h1 className="big-helper-text">{t('homeLangs.availableLangs')}</h1>
        <div className="flags-wrapper">
          {flags.map((flag) => (
            <div key={flag.id} className="flag-col">
              <img src={flag.icon} />
              <h6 style={{ margin: 0 }}>{t(`homeLangs.langs.${flag.id}.flag`)}</h6>
            </div>
          ))}
        </div>
        <p className="text-md">
          {t('homeLangs.desc-one')}{" "}
          <br />
          {t('homeLangs.desc-two')}
          <b>
            &nbsp; {t('homeLangs.desc-three')} <br /> {t('homeLangs.desc-four')}
          </b>
          <br />
          {t('homeLangs.desc-five')}
        </p>
      </div>
      <div className="cards-wrapper">
        <div className="row card-spacer directioner">
          {cardsOne.map((card) => (
            <Card
              key={card.id}
              imageUrl={card.url}
              titleLanguage={card.title}
              textLanguage={t(`homeLangs.langs.${card.id}.desc`)}
              buttonText={t(`homeLangs.langs.${card.id}.buttonText`)}
              language={card.language}
              link={card.link}
            />
          ))}
        </div>
        <div className="row card-spacer directioner">
          {cardsTwo.map((card) => (
            <Card
              key={card.id}
              imageUrl={card.url}
              titleLanguage={card.title}
              textLanguage={t(`homeLangs.langs.${card.id}.desc`)}
              buttonText={t(`homeLangs.langs.${card.id}.buttonText`)}
              language={card.language}
              link={card.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomeLanguages;
