import React from "react";
import "./NotFound.scss";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const {t, i18n} = useTranslation();
  return (
    <section className="not-found-section">
      <div className="inner-wrapper">
        <h1 className="error-big">404</h1>
        <h1 className="error-md">
          {t('notFound.msg')}
        </h1>
      </div>
    </section>
  );
};

export default NotFound;
