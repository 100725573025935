import React from "react";
import { Link } from "react-router-dom";
import data from "../../../data/data-es.json";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import ContactForm from "../ContactForm";
import "./ContactBusiness.scss";
import { useTranslation } from "react-i18next";

const ContactBusiness = () => {
  const hero = data.businessHero;
  const contact = data.contactSection;
  const { t, i18n } = useTranslation();

  const templateID = "template_2l7syip";
  const inputs = [
    {
      id: 0,
      name: "from_name",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[0].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[0].id}.err`),
      required: true,
    },
    {
      id: 0,
      name: "from_lName",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[1].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[1].id}.err`),
      required: true,
    },
    {
      id: 1,
      name: "business_name",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[5].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[5].id}.err`),
      required: true,
    },
    {
      id: 2,
      name: "user_phone",
      type: "number",
      placeholder: t(`homeContact.inputs.${contact[2].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[2].id}.err`),
      required: true,
    },
    {
      id: 3,
      name: "business_role",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[6].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[6].id}.err`),
      required: true,
    },
    {
      id: 4,
      name: "business_area",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[7].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[7].id}.err`),
      required: true,
    },
    {
      id: 5,
      name: "employees",
      type: "number",
      placeholder: t(`homeContact.inputs.${contact[8].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[8].id}.err`),
      required: true,
    },
    {
      id: 6,
      name: "user_email",
      type: "email",
      placeholder: t(`homeContact.inputs.${contact[3].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[3].id}.err`),
      required: true,
    },
    {
      id: 7,
      name: "message",
      type: "text",
      placeholder: t(`homeContact.inputs.${contact[4].id}.input`),
      errorMessage: t(`homeContact.inputs.${contact[4].id}.err`),
      required: true,
    },
  ];

  return (
    <section className="contact-business">
      <div className="title-wrapper">
        <h1>{t("homeContact.title")}</h1>
        <span>
          {t("homeContact.email-one")}{" "}
          <a href="mailto:info@ukodesh.com">
            <b>info@ukodesh.com</b>
          </a>{" "}
          {t("homeContact.email-two")}
        </span>
      </div>
      <div className="inner-wrapper mt-4">
        <div className="left-side">
          <img src={hero.heroBanner} width={256} alt="" />
          <span className="mt-3 txt-ctc">{t("homeContact.contact-us")}</span>
          <div className="divider mt-2" />
          <div className="media-wrapper mt-2">
            <div className="detail-wrapper">
              <FaPhoneAlt />
              <span className="detail-txt ml-1">{"+52 (33) 1344 1347"}</span>
            </div>
            <div className="detail-wrapper mt-1">
              <FaEnvelope />
              <span className="detail-txt ml-1">
                <a href="mailto:info@ukodesh.com">info@ukodesh.com</a>
              </span>
            </div>
          </div>
        </div>
        <div className="right-side">
          <ContactForm cert={false} templateID={templateID} inputs={inputs} />
        </div>
      </div>
      <span className="mt-5 centered-text">
        {t("homeContact.personalInterest")}{" "}
        <Link to="/contact/student">
          <b>{t("homeContact.contact-here")}</b>
        </Link>
      </span>
    </section>
  );
};

export default ContactBusiness;
