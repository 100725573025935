import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";


const FooterMobile = ({footer, shared, social}) => {
  const {t, i18n} = useTranslation();
  return (
    <div className="centered-section">
      <div className="column spacer">
        <div className="logo-section">
          <Link to="/">
            <img style={{filter: 'revert'}}
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1697325871/ukodesh-media/Ukodesh_Logotipo_WH_ntfjxw.png"
              alt=""
            />
          </Link>
        </div>
        <div className="contact-details">
          <b className="white-font mt-3">{t('footer.contact-us')}</b>
          <label className="white-font">{footer.phoneOne}</label>
          <div className="email-wrapper mt-2">
            <b className="white-font">{footer.emailTitle}:</b>{" "}
            <a className="white-font" href="emailto:info@ukodesh.com">
              {footer.email}
            </a>
          </div>
          <div className="social-network mt-2">
            {social.map((icon, index) => (
              <a target={"_blank"} key={index} href={icon.link} alt={icon.name}>
                <img src={icon.icon} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterMobile