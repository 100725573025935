import React, { useState, useEffect } from "react";
import "./AppBanner.scss";
import data from "../../data/data-es.json";
import { useTranslation } from "react-i18next";

const AppBanner = ({ backgroundColor }) => {
  const banner = data.appBanner;
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1024;
  const {t, i18n} = useTranslation();

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  return (
    <section className={`appBanner-wrapper ${backgroundColor}`}>
      <div className="banner-inner">
        <div className="column banner-left">
          <div className="banner-left-wrapper">
            <div className="row text-md">
              <div className="column-direction text-wrapper">
                <h1>{t('bannerSection.title')}</h1>
                <span dangerouslySetInnerHTML={{ __html: t('bannerSection.desc') }}></span>
              </div>
            </div>
            <div
              className={`row column-direction text-md ${
                width <= breakpoint ? "mt-3" : "mt-6"
              }`}
            >
              {width <= breakpoint ? (
                <img
                  className="mobile-app"
                  src={banner.bannerImageMobile}
                  alt=""
                />
              ) : null}
              <span className={` ${width <= breakpoint ? "mt-3" : ""}`}>
                <b>{t('bannerSection.downloadText')}</b>
              </span>
              <div className="app-buttons mt-2">
                <a
                  target={"_blank"}
                  href="https://apps.apple.com/es/app/dexway/id489465002"
                >
                  <img src={banner.bannerAppStore} alt="" />
                </a>
                <a
                  target={"_blank"}
                  href="https://play.google.com/store/apps/details?id=com.dexway.dexway_tablet&hl=es&gl=US&pli=1"
                >
                  <img src={banner.bannerGooglePlay} alt="" />
                </a>
                {/*<a href="">
                  <img src={banner.bannerAppGallery} alt="" />
              </a>*/}
              </div>
            </div>
          </div>
        </div>
        {width > breakpoint ? (
          <div className="column banner-right">
            <img src={banner.bannerImage} alt="" />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default AppBanner;
