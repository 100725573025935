import React, { useState, useEffect } from "react";
import "./Levels.scss";
import data from "../../data/data-es.json";
import LevelsDesk from "./LevelsDesk";
import LevelsMobile from "./LevelsMobile";

const Levels = ({
  colorFont,
  activeBar,
  unactiveBar,
  activeElement,
  unactiveElement,
  activeSelector,
  unactiveSelector,
}) => {
  const [isActive, setIsActive] = useState("");
  const [textLevel, setTextLevel] = useState("");
  const [levelName, setLevelName] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const levels = data.levels;

  const breakpoint = 1024;

  let uniqueCounter = levels.filter(
    (count, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.layoutPosition === count.layoutPosition &&
          t.levelSkill === count.levelSkill
      )
  );

  const handleClick = (text) => {
    setTextLevel(text);
    setIsActive(text);
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  return width > breakpoint ? (
    <LevelsDesk
      uniqueCounter={uniqueCounter}
      handleClick={handleClick}
      colorFont={colorFont}
      textLevel={textLevel}
      isActive={isActive}
      levels={levels}
      activeBar={activeBar}
      unactiveBar={unactiveBar}
      activeElement={activeElement}
      unactiveElement={unactiveElement}
      activeSelector={activeSelector}
      unactiveSelector={unactiveSelector}
    />
  ) : (
    <LevelsMobile
      uniqueCounter={uniqueCounter}
      handleClick={handleClick}
      colorFont={colorFont}
      textLevel={textLevel}
      isActive={isActive}
      levels={levels}
      activeBar={activeBar}
      unactiveBar={unactiveBar}
      activeElement={activeElement}
      unactiveElement={unactiveElement}
      activeSelector={activeSelector}
      unactiveSelector={unactiveSelector}
    />
  );
};

export default Levels;
