import React from "react";
import { Link } from "react-router-dom";
import terms from "../../docs/terminos-y-condiciones.pdf";
import privacy from "../../docs/aviso-de-privacidad.pdf";
import { useTranslation } from "react-i18next";


const FooterDesk = ({ footer, shared, social }) => {
  const {t, i18n} = useTranslation();
  return (
    <div className="row">
      <div className="column spacer">
        <div className="logo-section">
          <Link to="/">
            <img
              style={{filter: 'revert'}}
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1697325871/ukodesh-media/Ukodesh_Logotipo_WH_ntfjxw.png"
              alt=""
            />
          </Link>
        </div>
        <div className="contact-details">
          <b className="white-font mt-3">{t('footer.contact-us')}</b>
          <label className="white-font">{footer.phoneOne}</label>
          <div className="email-wrapper mt-2">
            <b className="white-font">{footer.emailTitle}:</b>{" "}
            <a className="white-font" href="mailto:info@ukodesh.com">
              {footer.email}
            </a>
          </div>
          <div className="social-network mt-2">
            {social.map((icon, index) => (
              <a target={"_blank"} key={index} href={icon.link} alt={icon.name}>
                <img src={icon.icon} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="column spacer">
        <b className="white-font">{t('footer.explore')}</b>
        <div className="links-wrapper mt-3">
          <Link className="white-font capitalize" to="/">
            {footer.exploreOne}
          </Link>
          <Link className="white-font capitalize" to="/business">
          {t('footer.companies')}
          </Link>
          <Link className="white-font capitalize" to="/contact">
          {t('footer.contact')}
          </Link>
        </div>
      </div>
      <div className="column spacer">
        <b className="white-font">{footer.legalTitle}</b>
        <div className="links-wrapper mt-3">
          <a className="white-font capitalize" href={terms} target={"_blank"}>
          {t('footer.terms')}
          </a>
          <a className="white-font capitalize" href={privacy} target={"_blank"}>
          {t('footer.privacy')}
          </a>
        </div>
      </div>
      <div className="column spacer">
        <div className="buttons-section">
          <a
            target={"_blank"}
            href={shared.loginButtonLink}
            className="login-btn-footer mr-24"
          >
          {t('footer.logIn')}
          </a>
          <a
            target={"_blank"}
            href={shared.testButtonLink}
            className="test-btn-footer"
          >
          {t('footer.test')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterDesk;
