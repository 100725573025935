import React from "react";
import { Link } from "react-router-dom";
import "./Certified.scss";
import { useTranslation } from "react-i18next";

const Certified = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="certified-section">
      <div className="inner-wrapper">
        <div className="inner-text max-w-400">
          <h1 className="white-font">{t('certPage.banner.title')}</h1>
          <span className="white-font">
          {t('certPage.banner.desc')}
          </span>
        </div>
        <div className="">
          <Link
            className="cert-btn"
            target="_blank"
            to={"https://evaluation.eecertification.com/certifications/new"}
          >
            {t('certPage.banner.button')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Certified;
