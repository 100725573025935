import React, { useState, useEffect } from "react";
import "./Footer.scss";
import data from "../../data/data-es.json";
import FooterDesk from "./FooterDesk";
import FooterMobile from "./FooterMobile";

const Footer = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1024;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  const footer = data.footer;
  const social = data.social;
  const shared = data.shared;

  return (
    <footer className="footer-section">
      <div className="footer-wrapper">
        {width > breakpoint ? (
          <FooterDesk footer={footer} social={social} shared={shared} />
        ) : <FooterMobile footer={footer} social={social} shared={shared} />}
        <div className="row"></div>
      </div>
    </footer>
  );
};

export default Footer;
