import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import data from "../../data/data-es.json";
import NavBar from "../Navbar/NavBar";
import { useTranslation } from "react-i18next";

const Header = ({ toggle }) => {
  const header = data.footer;
  const shared = data.shared;
  const { t, i18n } = useTranslation();

  return (
    <header className="header-section">
      <div className="header-wrapper">
        <div className="logo-section">
          <Link to="/">
            <img
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1697325858/ukodesh-media/Ukodesh_Logotipo_BL_ab6hv9.png"
              alt=""
            />
          </Link>
        </div>
        <NavBar header={header} shared={shared} toggle={toggle} />
      </div>
    </header>
  );
};

export default Header;
