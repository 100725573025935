import React from "react";
import ContactStudent from "./ContactStudent/ContactStudent";
import "./Contact.scss";

const Contact = () => {
  return (
    <section className="contact-landing">
      <ContactStudent />
    </section>
  );
};

export default Contact;
