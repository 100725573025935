import React, { useState, useEffect } from "react";
import "./HomeSkills.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeSkills = () => {
  const { t, i18n } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 912;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  })

  return (
    <>
      <section className="skills-section centered-section">
        <span className="title-md centered-text">
          {t("homeSkills.big-title-one")} <br /> {t("homeSkills.big-title-two")}{" "}
          <br /> {t("homeSkills.big-title-three")}{" "}
          <b>
            {t("homeSkills.big-title-four")}
            <br />
          </b>{" "}
          {t("homeSkills.big-title-five")}{" "}
          <span className="secondary-gradient">
            {t("homeSkills.big-title-six")}
          </span>
        </span>
        <div className="skills-wrapper mt-5">
          <div className="row centered-section mb-70">
            <div className="skill-tag white-bg mr-150">
              <img
                className="mr-24"
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1675639785/ukodesh-media/Group_62_k5pcdb.svg"
                alt=""
              />
              {t("homeSkills.first-skill")}
            </div>
            {width > breakpoint ? (<Link
              className="skill-tag blue-bg button xl-width mr-150"
              to="/contact/student"
            >
              {t("homeSkills.startButton")}
              <img
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1675030656/ukodesh-media/Group_83_krdf3e.svg"
                alt=""
              />
            </Link>) : null}
            <div className="skill-tag white-bg">
              <img
                className="mr-24"
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1675639785/ukodesh-media/Group_64_tvls7m.svg"
                alt=""
              />
              {t("homeSkills.second-skill")}
            </div>
          </div>
          <div className="row centered-section">
            <div className="skill-tag white-bg mr-150">
              <img
                className="mr-24"
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1675639785/ukodesh-media/Group_63_yv8m0m.svg"
                alt=""
              />
              {t("homeSkills.third-skill")}
            </div>
            <div className="skill-tag white-bg ml-150">
              <img
                className="mr-24"
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1675639785/ukodesh-media/Group_65_sac3iu.svg"
                alt=""
              />
              {t("homeSkills.fourth-skill")}
            </div>
            {width < breakpoint ? (<Link
              className="skill-tag blue-bg button xl-width mr-150"
              to="/contact/student"
            >
              {t("homeSkills.startButton")}
              <img
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1675030656/ukodesh-media/Group_83_krdf3e.svg"
                alt=""
              />
            </Link>) : null}
          </div>
        </div>
        <div className="girl-wrapper">
          <img
            src="https://res.cloudinary.com/droimgtqi/image/upload/v1675639785/ukodesh-media/image_5_gmer6d.png"
            alt=""
          />
        </div>
      </section>
    </>
  );
};

export default HomeSkills;
