import React from "react";
import "./Partners.scss";
import data from "../../data/data-es.json";

const Partners = () => {
  const partners = data.partners;
  return (
    <div className="partners-wrapper">
      {partners.map((partner, index) => (
        <img
          className="partner-tag"
          key={index}
          src={partner.partnerImg}
          alt={partner.partnerName}
        />
      ))}
    </div>
  );
};

export default Partners;
