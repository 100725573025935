import React from "react";
import LevelSelect from "./LevelSelect";
import LevelText from "./LevelText";
import { useTranslation } from "react-i18next";

const LevelsDesk = ({
  uniqueCounter,
  handleClick,
  colorFont,
  textLevel,
  isActive,
  levels,
  activeBar,
  unactiveBar,
  activeElement,
  unactiveElement,
  activeSelector,
  unactiveSelector
}) => {

  const { t, i18n } = useTranslation();

  return (
    <div className="levels-wrapper">
      <div className="levels-content">
        {uniqueCounter.map((position, index) => (
          <div
            key={index}
            className={`levels-container ${position.layoutPosition}`}
          >
            <div className="levels-header">
              <span className={`level-font ${colorFont}`}>
              {t(`homeLevels.levels.${position.id}.title`)}
              </span>
            </div>
            <div className="levels-body">
              <LevelSelect
                levels={levels}
                filterBy={position.layoutPosition}
                handleClick={handleClick}
                setIsActive={isActive}
                activeElement={activeElement}
                unactiveElement={unactiveElement}
                activeSelector={activeSelector}
                unactiveSelector={unactiveSelector}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="levels-footer">
        <LevelText
          levels={levels}
          colorFont={colorFont}
          textLevel={textLevel}
          setIsActive={isActive}
          activeBar={activeBar}
          unactiveBar={unactiveBar}
        />
      </div>
    </div>
  );
};

export default LevelsDesk;
