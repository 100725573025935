import React, { useState, useEffect } from "react";
import "./BusinessBenefits.scss";
import data from "../../../data/data-es.json";
import SmallContainer from "../../../components/SmallContainer/SmallContainer";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const Benefit = ({ benefit }) => {
  return (
    <div className="benefit-card">
      <div className="benefit-wrapper">
        <img src={benefit.link} alt="" />
        <b className="mt-1">{t(`businessBenefits.benefits.${benefit.id}.title`)}</b>
        <span className="mt-1">{t(`businessBenefits.benefits.${benefit.id}.desc`)}</span>
      </div>
    </div>
  );
};

const BusinessBenefits = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1024;
  const {t, i18n} = useTranslation();

  useEffect(() => {
    window.addEventListener("resize", setWidth(window.innerWidth));
  });
  const benefits = data.businessBenefits;
  const detailsOne = data.businessBenefits.benefitsDetailsOne;
  const detailsTwo = data.businessBenefits.benefitsDetailsTwo;
  return (
    <section className="business-benefits mt-10">
      <SmallContainer>{t('businessBenefits.title')}</SmallContainer>
      <section className="business-details">
        <div className="details-wrapper">
          <div className="full-row">
            {detailsOne.map((benefit, index) => (
              <Benefit key={index} benefit={benefit} />
            ))}
          </div>
          <div className={`full-row ${width > breakpoint ? "mt-8" : "mt-5"}`}>
            {detailsTwo.map((benefit, index) => (
              <Benefit key={index} benefit={benefit} />
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};

export default BusinessBenefits;
