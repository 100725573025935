import React from "react";
import "./HomePartners.scss";
import data from "../../../data/data-es.json";
import Partners from "../../../components/Partners/Partners";
import { useTranslation } from "react-i18next";

const HomePartners = () => {
  const partnerTitle = data.partnersTitle;
  const {t, i18n} = useTranslation();

  return (
    <section className="partners-section">
      <h1 className="big-helper-text mt-9">{t('homePartners.title')}</h1>
      <div className="row centered-section mt-5 mb-100">
        <Partners />
      </div>
    </section>
  );
};

export default HomePartners;
