import React from "react";
import Levels from "../../../components/Levels/Levels";
import "./BusinessLevel.scss";
import { useTranslation } from "react-i18next";

const BusinessLevel = () => {
  const white = "white-font";
  const activeBar = "active-bar-business";
  const unactiveBar = "unactive-bar-business";
  const activeElement = "active-element-business";
  const unactiveElement = "unactive-element-business";
  const activeSelector = "active-selector-business";
  const unactiveSelector = "unactive-selector-business";
  const {t, i18n} = useTranslation();
  return (
    <section className="business-levels">
      <h1 className="big-blue-text pb-4">{t('homeLevels.title')}</h1>
      <div className="levels-background">
        <div className="levels-wrapper">
          <Levels
            colorFont={white}
            activeBar={activeBar}
            unactiveBar={unactiveBar}
            activeElement={activeElement}
            unactiveElement={unactiveElement}
            activeSelector={activeSelector}
            unactiveSelector={unactiveSelector}
          />
        </div>
      </div>
    </section>
  );
};

export default BusinessLevel;
