import React from "react";
import { Link } from "react-router-dom";
import "./BusinessWork.scss";
import SmallContainer from "../../../components/SmallContainer/SmallContainer";
import data from "../../../data/data-es.json";
import { useTranslation } from "react-i18next";

const BWork = ({ work }) => {
  const {t, i18n} = useTranslation();
  return (
    <div className="benefit-card">
      <div className="benefit-wrapper">
        <img src={work.link} alt="" />
        <span className="mt-1">{t(`businessWork.functions.${work.id}.text`)}</span>
      </div>
    </div>
  );
};

const BusinessWork = () => {
  const title = data.businessWorkTitle;
  const functions = data.businessFunctions;
  const {t, i18n} = useTranslation();
  
  return (
    <section className="business-work">
      <SmallContainer>{t('businessWork.title')}</SmallContainer>
      <section className="business-details">
        <div className="details-wrapper">
          <div className="full-row mt-4">
            <img className="image-featureT" src={data.media.featureTwo} alt="" />
          </div>
          <div className="full-row mt-8">
            {functions.map((work, index) => (
              <BWork work={work} key={index} />
            ))}
          </div>
          <div className="full-row mt-2">
          <Link className="bsns-btn mt-6" to="/contact/business">
            {t('businessWork.signButton')}{" "}
            <img className="ml-3"
              src="https://res.cloudinary.com/droimgtqi/image/upload/v1677723416/Group_83_i0d0q2.svg"
              alt=""
            />
          </Link>
          </div>
        </div>
      </section>
    </section>
  );
};

export default BusinessWork;
