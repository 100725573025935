import React from "react";
import "./WhatsAppButton.scss";

const WhatsAppButton = () => {
  return (
    <a href="https://wa.me/message/KRTZX4LDCNU4N1" className="whats-button" target={"_blank"}>
      <div className="inner-wrapper">
        <img
          width={100}
          src="https://res.cloudinary.com/droimgtqi/image/upload/v1677784437/Digital_Inline_White_naym4y.svg"
          alt=""
        />
      </div>
    </a>
  );
};

export default WhatsAppButton;
