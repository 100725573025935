import React, { useRef, useState } from "react";
import data from "../../data/data-es.json";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import ContactInput from "./ContactInput";
import { useTranslation } from "react-i18next";

const ContactForm = ({ templateID, inputs, cert }) => {
  const form = useRef();
  const SERVICE_ID = "service_dyhnadc";
  const TEMPLATE_ID = templateID;
  const USER_ID = "icP0MQm94lJDmjpTN";
  const { t, i18n } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const [values, setValues] = useState({
    from_name: "",
    from_lName: "",
    business_name: "",
    user_phone: "",
    business_role: "",
    business_area: "",
    employees: "",
    user_email: "",
    message: "",
    level: "",
    area: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
      (result) => {
        Swal.fire({
          icon: "success",
          title: t("form.success"),
          text: t("form.successMsg"),
        });
        setValues({
          from_name: "",
          from_lName: "",
          business_name: "",
          user_phone: "",
          business_role: "",
          business_area: "",
          employees: "",
          user_email: "",
          message: "",
          level: "",
          area: "",
        });
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: t("form.error"),
          text: error.text,
        });
      }
    );
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="contact-form">
      <form ref={form} className="inner-wrapper" onSubmit={handleSubmit}>
        {inputs.map((input) => (
          <ContactInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        {cert && (
          <div className="cert">
            <div className="formInput mt-2">
              <select name="level" id="level">
                <option disabled selected value={t("form.level.default")}>
                  {t("form.level.default")}
                </option>
                <option value={t("form.level.one")}>
                  {t("form.level.one")}
                </option>
                <option value={t("form.level.two")}>
                  {t("form.level.two")}
                </option>
              </select>
            </div>
            <div className="formInput mt-2">
              <select name="area" id="area">
                <option disabled selected value={t("form.area.default")}>
                  {t("form.area.default")}
                </option>
                <option value={t("form.area.one")}>{t("form.area.one")}</option>
                <option value={t("form.area.two")}>{t("form.area.two")}</option>
                <option value={t("form.area.three")}>
                  {t("form.area.three")}
                </option>
              </select>
            </div>
          </div>
        )}
        <div className="agree-terms mt-4">
          <input
            type="checkbox"
            checked={disabled}
            onChange={(e) => setDisabled(e.target.checked)}
            name="agree-terms"
            id="agree-terms"
          />
          <span style={{ maxWidth: 240, fontSize: 13 }}>
            {t("form.agreement")}
          </span>
        </div>
        <button
          disabled={!disabled}
          type="submit"
          className={`submit-btn mt-2 ${
            disabled ? "blue-bg" : "blue-light-bg"
          } `}
        >
          {t("form.sendMsg")}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
