import React from "react";
import "./SmallContainer.scss";

const SmallContainer = ({ children }) => {
  return (
    <section className="small-container">
      <div className="container-wrapper">
        <span className="white-font">{children}</span>
      </div>
    </section>
  );
};

export default SmallContainer;
