import React from "react";
import "./Business.scss";
import BusinessBanner from "./BusinessBanner/BusinessBanner";
import BusinessBenefits from "./BusinessBenefits/BusinessBenefits";
import BusinessFeatures from "./BusinessFeatures/BusinessFeatures";
import BusinessHero from "./BusinessHero/BusinessHero";
import BusinessLanguages from "./BusinessLanguages/BusinessLanguages";
import BusinessLevel from "./BusinessLevel/BusinessLevel";
import BusinessPartners from "./BusinessPartners/BusinessPartners";
import BusinessReviews from "./BusinessReviews/BusinessReviews";
import BusinessWork from "./BusinessWork/BusinessWork";
import BusinessXFeatures from "./BusinessXFeatures/BusinessXFeatures";

const Business = () => {
  return (
    <section className="business-landing">
      <BusinessHero />
      <BusinessPartners />
      <BusinessBenefits />
      <BusinessFeatures />
      <BusinessWork />
      <BusinessLanguages />
      <BusinessLevel />
      <BusinessXFeatures />
      <BusinessReviews />
      <BusinessBanner />
    </section>
  );
};

export default Business;
