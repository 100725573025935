import "./App.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import FullBar from "./components/FullBar/FullBar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Business from "./pages/Business/Business";
import Contact from "./pages/Contact/Contact";
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton";
import NotFound from "./pages/NotFound/NotFound";
import ContactStudent from "./pages/Contact/ContactStudent/ContactStudent";
import ContactBusiness from "./pages/Contact/ContactBusiness/ContactBusiness";
import Certification from "./pages/Certification/Certification";
import Topbar from "./components/Topbar/Topbar";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  return (
    <div className="App">
      <Topbar />
      <Header toggle={toggle} />
      <FullBar isOpen={isOpen} toggle={toggle} />
      <div
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === "fadeOut" && location !== displayLocation) {
            setTransistionStage("fadeIn");
            setDisplayLocation(location);
          }
        }}
      >
        <Routes location={displayLocation}>
          <Route index element={<Home />} />
          <Route path="/business" element={<Business />} />
          <Route path="/certification" element={<Certification />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/student" element={<ContactStudent />} />
          <Route path="/contact/business" element={<ContactBusiness />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
      <WhatsAppButton />
    </div>
  );
}

export default App;
