import React from 'react'
import { useTranslation } from 'react-i18next';
import './Feature.scss'

const Feature = ({ feature }) => {
  const { t, i18n } = useTranslation();
    return (
      <div className="feature-card">
        <div className="feature-wrapper">
          <img src={t(`certPage.certFeatures.${feature.id}.link`)} alt="" />
          <b>{t(`certPage.certFeatures.${feature.id}.title`)}</b>
          <span className="mt-1" dangerouslySetInnerHTML={{__html: t(`certPage.certFeatures.${feature.id}.desc`)}} ></span>
        </div>
      </div>
    )
  }

export default Feature